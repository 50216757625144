body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3 {
    margin: 0 0 1em 0;
}

a {
    color: #e66625;
    text-decoration: none;
}

p {
    margin-top: 0;
    margin-bottom: 1em;
}

.container {
    display: grid;
    height: 100vh;
    grid-template-columns: 300px 3fr 1fr;
    grid-template-rows: 50px 40px minmax(0, 1fr) 40px;
    overflow: hidden;
}

.header,
.footer {
    grid-column: 1 / -1;
    background: #505050;
    display: flex;
    justify-content: space-between;
}

    .footer .copyright {
        line-height: 40px;
        color: white;
        font-size: 15px;
        margin-left: 15px;
    }

.app-name {
    width: 300px;
    overflow: hidden;
}

.breadcrumb {
    flex: 1;
}

.user {
    flex: 0.5;
    display: flex;
    flex-direction: row-reverse;
    background: #e66625;
    padding-left: 25px;
}

.menu {
    background: rgba(0, 0, 0, 0.4);
    grid-row: 2 / 4;
}

.command-bar {
    grid-column: 2 / -1;
}

.content {
    grid-column: 2 / -1;
    grid-row: 3 / 4;
    overflow-y: auto;
    background: white;
    padding: 2em;
}

.additional {
    background: rgba(0, 0, 0, 0.4);
    display: none;
    flex-direction: column;
}

    .additional .panel {
        background-color: rgba(255, 255, 255, 0.9);
        padding: 15px;
        box-shadow: 0 6.4px 14.4px 0 rgba(255,255,255,.132), 0 1.2px 3.6px 0 rgba(255,255,255,.108);
        border-radius: 2px;
        margin-bottom: 25px;
    }

.hidden {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*:focus {
    outline: none;
}

@media only screen and (max-width: 1024px) {
    .container {
        grid-template-columns: 50px auto;
    }

    .menu {
        color: white !important;
    }

    .app-name {
        width: 50px;
    }

    .user {
        background: transparent;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        grid-template-columns: auto;
    }

    .menu {
        display: none;
    }

    .command-bar {
        grid-column: 1 / -1;
    }

    .content {
        grid-column: 1 / -1;
    }
}

@media only screen and (min-width: 1800px) {
    .container.additional-closed {
        grid-template-columns: 300px 3fr 40px;
    }

    .content {
        grid-column: 2 / 3;
    }

    .additional {
        display: block;
        grid-column: 3 / -1;
        grid-row: 3 / 4;
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(208, 208, 208);
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    }

/* UI Fabric Component Fixes */

.ms-DetailsList .ms-DetailsRow {
    color: #605e5d;
}
